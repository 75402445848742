import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import { setDataTestId } from 'src/utils/setDataTestId';
import CustomTypography from '../CustomTypography';
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg';
import AddIcon from '@mui/icons-material/Add';
const CustomModal = ({
  title,
  subTitle,
  isOpen,
  onClose,
  children,
  closeBtnTitle,
  submitBtnTitle,
  onSubmit,
  closeButtonColor = 'error',
  isCloseBtnFullWidth,
  hideCloseButton,
  data_test_id,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        backdropFilter: 'blur(0px)',
        backgroundColor: '#2D2D2D33',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          p: '40px',
          borderRadius: '16px',
        }}
      >
        <Box>
          <CustomTypography variant="body2">{title}</CustomTypography>
        </Box>
        <Box mt={2}>
          <CustomTypography variant="body1" color="#797F8B">
            {subTitle}
          </CustomTypography>
        </Box>
        <Box>{children}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={5}>
          {!hideCloseButton && (
            <Button
              fullWidth={isCloseBtnFullWidth}
              variant="contained"
              color={closeButtonColor}
              onClick={onClose}
              sx={{
                mr: 2,
                textTransform: 'none',
                backgroundColor: '#fff',
                borderRadius: '28px',
                height: '100%',
                boxShadow: 'none',
                p: '10px 16px',
                color: '#000',
                border: '1px solid #D0D5DD',

                '&:hover': {
                  backgroundColor: '#ec4040',
                  color: '#fff',
                },

                '& path': {
                  stroke: '#fff',
                },
              }}
              disableRipple
            >
              <CustomTypography variant="body2">
                {closeBtnTitle ? closeBtnTitle : 'Закрыть'}
              </CustomTypography>
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                backgroundColor: '#EC407A',
                borderRadius: '28px',
                height: '100%',
                boxShadow: 'none',
                p: '10px 16px',

                '& path': {
                  stroke: '#fff',
                },
              }}
              disableRipple
              onClick={onSubmit}
              {...setDataTestId({ name: data_test_id })}
            >
              <CustomTypography variant="body2">
                {submitBtnTitle ? submitBtnTitle : 'Отправить'}
              </CustomTypography>
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
